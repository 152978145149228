import { Component, ViewChild } from '@angular/core';

import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { SharedModule } from '../../../../shared/shared.module';
import { AddressComponent } from '../address/address.component';
import { CompanyComponent } from '../company/company.component';
import { ContactComponent } from '../contact/contact.component';
import { MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { BankComponent } from '../bank/bank.component';
import { ActivityComponent } from '../activity/activity.component';
import { PartnerComponent } from '../partner/partner.component';
import { DocumentsComponent } from '../documents/documents.component';
import { DoneComponent } from '../done/done.component';
import { SuccessPageComponent } from '../../components/success-page/success-page.component';

@Component({
  selector: 'app-register',
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false},
    },
  ],
  standalone: true,
  imports: [
    AppMaterialModule,
    SharedModule,
    CompanyComponent,
    ContactComponent,
    AddressComponent,
    BankComponent,
    ActivityComponent,
    PartnerComponent,
    DocumentsComponent,
    DoneComponent,
    SuccessPageComponent
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent {
  @ViewChild('stepper') private stepper!: MatStepper;
  stepVisited: boolean[] = [false, false, false, false, false, false, false, false];
  isDone = false;

  onNext() {
    if (this.stepper.selected) {
      this.stepper.selected.completed = true;
    }

    if(!this.stepVisited[this.stepper.selectedIndex + 1])  this.stepVisited[this.stepper.selectedIndex + 1] = true;
    this.stepper.next();
  }

  onBack() {
    this.stepper.previous();
  }

  onDone() {
    this.isDone = true;
  }

  onSelectionChange(event: any) {
    if (event.previouslySelectedIndex > event.selectedIndex) {
      const currentStep = this.stepper.steps.toArray()[event.selectedIndex];
      currentStep.completed = false;
    }
  }
}
