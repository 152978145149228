import { Component, EventEmitter, inject, Output, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { SharedModule } from '../../../../shared/shared.module';
import { RegisterService } from '../../services/register.service';
import { IDone } from '../../../../core/interfaces/IDone';
import { Router } from '@angular/router';

@Component({
  selector: 'app-done',
  standalone: true,
  imports: [AppMaterialModule, SharedModule],
  templateUrl: './done.component.html',
  styleUrl: './done.component.scss'
})
export class DoneComponent {
  form!: FormGroup;
  hide = signal(true);

  @Output() isDone = new EventEmitter<void>();

  readonly formBuilder = inject(FormBuilder);
  readonly snackBar = inject(MatSnackBar);
  readonly service = inject(RegisterService);
  readonly router = inject(Router);

  constructor() {
    this.form = this.formBuilder.group({
      mail: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirm: ['', [Validators.required, Validators.minLength(6)]],
      bacenAccepted: [false],
      pepAccepted: [false]
    });
  }

  onDone() {
    console.log(this.form.value);
    if(!this.form.valid || !this.form.value.bacenAccepted || !this.form.value.pepAccepted){
      this.snackBar.open("Preencha todos os campos obrigatórios.", "OK");
      return;
    }

    if(this.form.value.password !== this.form.value.passwordConfirm){
      this.snackBar.open("As senhas não conferem.", "OK");
      return;
    }

    const signUp: IDone = {
      bacenAccepted: this.form.value.bacenAccepted,
      mail: this.form.value.mail,
      password: this.form.value.password,
      pepAccepted: this.form.value.pepAccepted
    };

    this.service.postSignup(signUp).subscribe({
      next: () => {
        this.isDone.emit();
        this.snackBar.open("Cadastro finalizado com sucesso.","OK");
      },
      error: (error) => {
        this.snackBar.open("Erro ao finalizar cadastro.","OK");
      }
    });
  }

  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }

}
