<h2 mat-dialog-title>Editar Contato</h2>
<mat-dialog-content>
  <form [formGroup]="form" class="p-2">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Nome</mat-label>
      <input type="name" required matInput placeholder="Ex. Carlos Alberto de Alvorada" formControlName="name">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>E-mail</mat-label>
      <input type="email" matInput placeholder="Ex. pat@example.com" formControlName="mail">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Cargo</mat-label>
      <input matInput placeholder="Ex. Contador" formControlName="role">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Celular</mat-label>
      <input
        matInput
        placeholder="Ex.(99) 9 9999-9999"
        mask="(00) 0 0000-0009"
        formControlName="phone"
      >
    </mat-form-field>
    <mat-slide-toggle formControlName="whatsapp" class="w-100">
      Este número é WhatsApp e aceito receber contato.
    </mat-slide-toggle>
    <mat-slide-toggle formControlName="mainContact" class="w-100 my-2">
      Este  contato  é o principal
    </mat-slide-toggle>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between p-2">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button cdkFocusInitial (click)="onSave()" class="bg-broad text-white">Salvar</button>
</mat-dialog-actions>
