<header>
  <section class="bg-primary d-flex justify-content-center align-items-center p-3">
    <img src="assets/svg/broadfactor-logo-branco.svg" alt="Logo da Broadfactor" class="w-logo">
  <!-- <div class="d-flex align-items-center text-white">
    <span class="title-antecipa">AntecipaGov</span>
    <div class="d-flex flex-column mx-2">
      <small  style="line-height: 1.5;">Cadastrar</small>
      <small  style="line-height: 1;">Fornecedor</small>
    </div>
  </div> -->
  </section>
</header>
