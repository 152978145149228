<h2 mat-dialog-title>Editar Sócio</h2>
<mat-dialog-content>
  <form
    [formGroup]="form"
    class="container p-2"
  >
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Nome</mat-label>
    <input
      matInput
      formControlName="name"
    >
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>E-mail</mat-label>
    <input
      matInput
      formControlName="mail"
    >
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>CPF</mat-label>
    <input
      matInput
      formControlName="cpfCnpj"
      mask="000.000.000-00"
    >
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Qualificação</mat-label>
    <mat-select  formControlName="qualificationCode">
      @for (qualification of qualificationList; track $index) {
        <mat-option [value]="qualification.code">{{ qualification.code }} - {{qualification.name}}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Celular</mat-label>
    <input
      matInput
      formControlName="phone"
      mask="(00) 00000-0009"
    >
  </mat-form-field>

    <mat-slide-toggle formControlName="isSubscriber" class="d-block">
      Assinante: Pessoa responsável pela empresa conforme contrato social. (Deve possuir E-CPF)
    </mat-slide-toggle>
    <mat-slide-toggle formControlName="isSolidaryPartner" class="d-block my-3">
      Sócio Solidário
    </mat-slide-toggle>

    <app-upload-card
        *ngFor="let item of uploadList; let i = index;"
        [item]="item"
        (add)="addDocument($event, i)"
      >
      </app-upload-card>

  </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between p-2">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button cdkFocusInitial (click)="onSave()" class="bg-broad text-white">Salvar</button>
</mat-dialog-actions>
