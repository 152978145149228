<form
  class="container"
  [formGroup]="form"
  #formDirective="ngForm"
  (ngSubmit)="addBank(formDirective)"
>
  <section class="mb-4">
    <b class="d-block mb-2">Cadastrar Banco</b>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>Banco</mat-label>
        <mat-select formControlName="bank">
            @for (bank of bankList; track $index) {
              <mat-option [value]="bank">{{ bank.COMPE }} - {{bank.ShortName}}</mat-option>
            }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>Agência</mat-label>
        <input
          matInput
          formControlName="agency"
          withDigit
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>Conta</mat-label>
        <input
          matInput
          formControlName="account"
          withDigit
        >
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>Chave Pix <small>(Opcional)</small></mat-label>
        <input matInput formControlName="pix">
      </mat-form-field>

      <div class="row">
        <mat-slide-toggle formControlName="mainAccount">
          Esta conta é a  principal para recebíveis
        </mat-slide-toggle>
      </div>

    </div>

    <div class="row d-flex justify-content-end">
      <button type="submit" mat-flat-button class="col-md-2 bg-broad text-white">
        Adicionar
      </button>
    </div>
  </section>

  <section class="overflow">
    <b class="d-block mb-2">Bancos Cadastrados</b>
    <table mat-table [dataSource]="dataSource" class="mb-5">
      <!-- Action -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-center">
         Ações
        </th>
        <td mat-cell *matCellDef="let row" class="text-center">
         <button type="button" mat-icon-button (click)="editBank(dataSource.indexOf(row))">
            <mat-icon>edit</mat-icon>
         </button>
         <button type="button" mat-icon-button (click)="deleteBank(dataSource.indexOf(row))">
          <mat-icon>delete</mat-icon>
       </button>
        </td>
      </ng-container>

      <!-- Nome -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.code}} - {{element.name}} </td>
      </ng-container>

      <!-- Conta -->
      <ng-container matColumnDef="account">
        <th mat-header-cell *matHeaderCellDef> Conta </th>
        <td mat-cell *matCellDef="let element"> {{element.account}} </td>
      </ng-container>

      <!-- Agência -->
      <ng-container matColumnDef="agency">
        <th mat-header-cell *matHeaderCellDef> Agência </th>
        <td mat-cell *matCellDef="let element"> {{element.agency}} </td>
      </ng-container>

      <!-- pix -->
      <ng-container matColumnDef="pix">
        <th mat-header-cell *matHeaderCellDef> Chave Pix </th>
        <td mat-cell *matCellDef="let element"> {{element.pix}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </section>


  <div class="d-flex justify-content-between">
    <button mat-button type="button" (click)="onBack()">
      voltar
    </button>
    <button mat-flat-button class="bg-broad text-white" type="button" (click)="onNext()">
      Avançar
    </button>
  </div>
</form>
