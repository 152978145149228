import { Component, EventEmitter, inject, Output } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { SharedModule } from '../../../../shared/shared.module';
import { UploadCardComponent } from '../../components/upload-card/upload-card.component';
import { Company } from '../../utils/company';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegisterService } from '../../services/register.service';
import { IUploadCard } from '../../../../core/interfaces/IUploadCard';

@Component({
  selector: 'app-documents',
  standalone: true,
  imports: [AppMaterialModule, SharedModule, UploadCardComponent],
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.scss'
})
export class DocumentsComponent {
  uploadList = Company.uploadCardList();

  @Output() nextStep = new EventEmitter<void>();
  @Output() backStep = new EventEmitter<void>();

  readonly formBuilder = inject(FormBuilder);
  readonly snackBar = inject(MatSnackBar);
  readonly service = inject(RegisterService);

  constructor () {
    this.getDocuments();
  }

  getDocuments() {
    this.service.getCompanyDocuments().subscribe({
      next: (documents) => {
        this.uploadList.forEach(uploadType => {
          uploadType.files = documents.filter(doc => !!doc.file && (doc.type === uploadType.type));
        })
      }
    })
  }

  addDocument(event: any, index: number) {
    const files = <FileList>event.target.files;
    this.uploadList[index].files = [...Array.from(files)];


    this.service.addCompanyDocuments(
      this.uploadList[index].files,
      this.uploadList[index].type
    )
    .subscribe({
      next: (document) => {
        this.snackBar.open('Documento adicionado com sucesso.', 'OK');
      },
      error: (error) => {
        this.snackBar.open('Erro ao adicionar documento.', 'OK');
      }
    })
  }

  onNext() {
    const funcValidation = (upload: IUploadCard) => {
      if(upload.required && !upload.files.length){
        return false;
      }

      return true;
    }

    if(!this.uploadList.every(funcValidation)) {
      this.snackBar.open('Faça upload de todos os documentos obrigatórios.', 'OK');
      return;
    }

    this.nextStep.emit();
  }

  onBack() {
    this.backStep.emit();
  }
}
