import { NgModule } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskDirective } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
};


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CurrencyMaskModule,
    NgFor,
    NgIf,
    NgxMaskDirective,
    ReactiveFormsModule,
  ],
  exports:[
    CommonModule,
    CurrencyMaskModule,
    NgFor,
    NgIf,
    NgxMaskDirective,
    ReactiveFormsModule
  ],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig},
  ]
})
export class SharedModule { }

