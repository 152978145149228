<form
  class="container"
  [formGroup]="form"
  #formDirective="ngForm"
>
  <section class="mb-4">
    <b class="d-block mb-2">Cadastrar Contato</b>
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>Nome</mat-label>
        <input type="name" required matInput placeholder="Ex. Carlos Alberto de Alvorada" formControlName="name">
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>E-mail</mat-label>
        <input type="email" matInput placeholder="Ex. pat@example.com" formControlName="mail">
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>Cargo</mat-label>
        <input matInput placeholder="Ex. Contador" formControlName="role">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>Celular</mat-label>
        <input
          matInput
          placeholder="Ex.(99) 9 9999-9999"
          mask="(00) 0 0000-0009"
          formControlName="phone"
        >
      </mat-form-field>
      <div class="col-md-5">
        <mat-slide-toggle formControlName="whatsapp">
          Este número é WhatsApp e aceito receber contato.
        </mat-slide-toggle>
        <mat-slide-toggle formControlName="mainContact" class="my-2">
          Este  contato  é o principal
        </mat-slide-toggle>
      </div>
    </div>

    <div class="row d-flex justify-content-end">
      <button type="submit" mat-flat-button class="col-md-2 bg-broad text-white" (click)="addContact(formDirective)">
        Adicionar
      </button>
    </div>
  </section>

  <section class="overflow">
    <b class="d-block mb-2">Contatos Cadastrados</b>
    <table mat-table [dataSource]="dataSource" class="mb-5">
      <!-- Action -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-center">
         Ações
        </th>
        <td mat-cell *matCellDef="let row" class="text-center">
          <button type="button" mat-icon-button (click)="editContact(row, dataSource.indexOf(row))">
            <mat-icon>edit</mat-icon>
          </button>
          <button type="button" mat-icon-button (click)="deleteContact(row, dataSource.indexOf(row))">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <!-- Nome -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
      <!-- E-mail -->
      <ng-container matColumnDef="mail">
        <th mat-header-cell *matHeaderCellDef> E-mail </th>
        <td mat-cell *matCellDef="let element"> {{element.mail}} </td>
      </ng-container>
      <!-- Celular -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Celular </th>
        <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
      </ng-container>
      <!-- Cargo -->
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef> Cargo </th>
        <td mat-cell *matCellDef="let element"> {{element.role}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </section>


  <div class="d-flex justify-content-between">
    <button mat-button type="button" (click)="onBack()">
      Voltar
    </button>
    <button mat-flat-button class="bg-broad text-white" type="button" (click)="onNext()">
      Avançar
    </button>
  </div>
</form>
