import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IActivity } from '../../../../core/interfaces/IActivity';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegisterService } from '../../services/register.service';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { SharedModule } from '../../../../shared/shared.module';

@Component({
  selector: 'app-activity-edit-modal',
  standalone: true,
  imports: [AppMaterialModule, SharedModule],
  templateUrl: './activity-edit-modal.component.html',
  styleUrl: './activity-edit-modal.component.scss'
})
export class ActivityEditModalComponent {
  readonly dialogRef = inject(MatDialogRef<ActivityEditModalComponent>);
  readonly data = inject<{activity: IActivity, activityList: IActivity[]}>(MAT_DIALOG_DATA);
  readonly formBuilder = inject(FormBuilder);
  readonly snackBar = inject(MatSnackBar);
  readonly service = inject(RegisterService);
  form!: FormGroup;

  constructor() {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: [this.data.activity.id],
      code: [this.data.activity.code, Validators.required],
      name: [this.data.activity.name],
      mainActivity: [this.data.activity.mainActivity],
    });

    this.form.get('code')?.valueChanges.subscribe((code) => {
      if(this.form.get('code')?.invalid) return;

      this.service.getEconomicActivitiesByCNAE(code).subscribe({
        next: (activity) => {
          if(!activity.length) {
            this.form.get('code')?.setErrors({invalid: true});
            return;
          }

          this.form.patchValue({name: activity[0].classe.descricao});
        },
        error: (error) => {
          this.snackBar.open('Ocorreu um erro ao buscar as informações do CNAE.', 'OK');
        }
      });
    });
  }

  hasMainActivity () {
    return !!this.data.activityList.find(activity => activity.mainActivity);
  }

  onSave() {
    if(this.form.invalid){
      this.snackBar.open('Preencha todos os campos.', 'OK');
      return;
    }

    if(this.form.value.mainActivity && this.hasMainActivity()) {
      this.snackBar.open('Já existe uma atividade principal.', 'OK');
      return;
    }

    this.dialogRef.close(this.form.value);
  }
}
