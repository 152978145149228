import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[withDigit]',
  standalone: true
})
export class WithDigitDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = input.value.replace(/-/g, '');

    const digits = value.match(/\d/g);

    if (digits && digits.length > 1) {
      value = value.slice(0, digits.length - 1) + '-' + value.slice(digits.length - 1);
    }

    this.renderer.setProperty(input, 'value', value);
  }
}
