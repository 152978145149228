<form
  [formGroup]="form"
  class="container my-5"
>
  <div class="row">
    <mat-form-field appearance="outline" class="col">
      <mat-label>CNPJ</mat-label>
      <input
        matInput
        required
        formControlName="cnpj"
        placeholder="00.000.000/0000-00"
        mask="00.000.000/0000-00"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Natureza legal</mat-label>
      <mat-select formControlName="legalNature">
        @for (item of natureLegalList; track $index) {
          <mat-option value="{{ item.name }}">{{ item.code }} - {{ item.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row">

    <mat-form-field appearance="outline">
      <mat-label>Razão social</mat-label>
      <input matInput required formControlName="name" />
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field appearance="outline" class="col">
      <mat-label>Nome comercial</mat-label>
      <input matInput required formControlName="fantasyName" />
    </mat-form-field>
  </div>



  <div class="row">
    <mat-form-field appearance="outline" class="col-md-6">
      <mat-label>Data de abertura</mat-label>
      <input
        matInput
        formControlName="openedOn"
        placeholder="DD/MM/AAAA"
        mask="d0/m0/0000"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-6">
      <mat-label>Unidade</mat-label>
      <mat-select required formControlName="unit">
        <mat-option value="HEAD_OFFICE">Matriz</mat-option>
        <mat-option value="BRANCH">Filial</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field appearance="outline" class="col-md-6">
      <mat-label>Capital social</mat-label>
      <input matInput required formControlName="sharedCapital" currencyMask/>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col">
      <mat-label>Faturamento anual</mat-label>
      <input matInput required formControlName="anualBilling" currencyMask/>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field appearance="outline" class="col-md-6">
      <mat-label>Regime tributário</mat-label>
      <mat-select required formControlName="regime">
        <mat-option value="SIMPLES_NACIONAL">Simples Nacional</mat-option>
        <mat-option value="LUCRO_PRESUMIDO">Lucro Presumido</mat-option>
        <mat-option value="LUCRO_REAL">Lucro Real</mat-option>
        <mat-option value="ISENTO">Isento</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col">
      <mat-label>Site</mat-label>
      <input matInput formControlName="site" />
    </mat-form-field>
  </div>
  <div class="d-flex justify-content-end">
    <button mat-flat-button class="bg-broad text-white align" (click)="onNext()">
      Avançar
    </button>
  </div>
</form>
