import { IUploadCard } from "../../../core/interfaces/IUploadCard"

export class Company {
  static legalNatureList() {
    return [
      {code: '201-1', name: 'Empresa Pública'},
      {code: '203-8', name: 'Sociedade de Economia Mista'},
      {code: '204-6', name: 'Sociedade Anônima Aberta'},
      {code: '205-4', name: 'Sociedade Anônima Fechada'},
      {code: '206-2', name: 'Sociedade Empresária Limitada'},
      {code: '207-0', name: 'Sociedade Empresária em Nome Coletivo'},
      {code: '208-9', name: 'Sociedade Empresária em Comandita Simples'},
      {code: '209-7', name: 'Sociedade Empresária em Comandita por Ações'},
      {code: '212-7', name: 'Sociedade em Conta de Participação'},
      {code: '213-5', name: 'Empresário Individual'},
      {code: '214-3', name: 'Cooperativa'},
      {code: '215-1', name: 'Consórcio de Sociedades'},
      {code: '216-0', name: 'Grupo de Sociedades'},
      {code: '217-8', name: 'Estabelecimento, no Brasil, de Sociedade Estrangeira'},
      {code: '219-4', name: 'Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira'},
      {code: '221-6', name: 'Empresa Domiciliada no Exterior'},
      {code: '222-4', name: 'Clube/Fundo de Investimento'},
      {code: '223-2', name: 'Sociedade Simples Pura'},
      {code: '224-0', name: 'Sociedade Simples Limitada'},
      {code: '225-9', name: 'Sociedade Simples em Nome Coletivo'},
      {code: '226-7', name: 'Sociedade Simples em Comandita Simples'},
      {code: '227-5', name: 'Empresa Binacional'},
      {code: '228-3', name: 'Consórcio de Empregadores'},
      {code: '229-1', name: 'Consórcio Simples'},
      {code: '230-5', name: 'Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)'},
      {code: '231-3', name: 'Empresa Individual de Responsabilidade Limitada (de Natureza Simples)'},
      {code: '232-1', name: 'Sociedade Unipessoal de Advogados'},
      {code: '233-0', name: 'Cooperativa de Consumo'},
    ]
  }

  static uploadCardList():IUploadCard[] {
    return [
      {
        description: "Cartão CNPJ",
        type: "CNPJ",
        required: true,
        files: [],
        delete: false,
        loading: false,
        multiple: false
      },
      {
        description: "Comprovante de endereço com menos de 60 dias",
        type: "ENDERECO",
        required: true,
        files: [],
        delete: false,
        loading: false,
        multiple: false
        },
      {
        description: "Contrato Social / Estatuto Social e suas alterações atualizados",
        type: "CONTRATO_SOCIAL",
        required: true,
        files: [],
        delete: false,
        loading: false,
        multiple: false
      },
      {
        description: "Quadro societário ou Representantes legais",
        type: "QUADRO_SOCIETARIO",
        required: true,
        files: [],
        delete: false,
        loading: false,
        multiple: false
      },
      {
        description: "Lista de endividamento da empresa",
        type: "ENDIVIDAMENTO_EMPRESA",
        required: true,
        files: [],
        delete: false,
        loading: false,
        multiple: false,
      },
      {
        description: "Balanço/Balancete dos últimos 2 exercícios contábeis",
        type: "PENULTIMO_BALANCO",
        required: true,
        files: [],
        delete: false,
        loading: false,
        multiple: false
      },
      {
        description: "Declaração de Faturamento ou Demonstrativo DAS (SIMPLES)",
        type: "FATURAMENTO",
        required: true,
        files: [],
        delete: false,
        loading: false,
        multiple: false
      },
      {
        description: "CND Federal",
        type: "CND_FEDERAL",
        required: false,
        files: [],
        delete: false,
        loading: false,
        multiple: false
      },
      {
          description: "CND Estadual",
          type: "CND_ESTADUAL",
          required: false,
          files: [],
          delete: false,
          loading: false,
          multiple: false
      },
      {
          description: "CND Municipal",
          type: "CND_MUNICIPAL",
          required: false,
          files: [],
          delete: false,
          loading: false,
          multiple: false
      },
      {
          description: "Certidão de processos judiciais no TJ do local da sede do devedor",
          type: "CERTIDAO_TJ",
          required: false,
          files: [],
          delete: false,
          loading: false,
          multiple: false
      },
      {
          description: "Certidão do cartório de protestos da comarca do local da sede do devedor",
          type: "CERTIDAO_PROTESTO",
          required: false,
          files: [],
          delete: false,
          loading: false,
          multiple: false
      },
      {
          description: "Relatório do SPC Serasa / Boa Vista",
          type: "RELATORIO_SPC_SERASA",
          required: false,
          files: [],
          delete: false,
          loading: false,
          multiple: false
      },
      {
          description: "Curva ABC de Clientes",
          type: "CURVA_ABC_CLIENTES",
          required: false,
          files: [],
          delete: false,
          loading: false,
          multiple: false
      },
      {
          description: "Edital de Pregão / Licitação (conforme aplicável)",
          type: "EDITAL_PREGAO_LICITACAO",
          required: false,
          files: [],
          delete: false,
          loading: false,
          multiple: false
      },
      {
          description: "Termo de Referência (se aplicável)",
          type: "TERMO_REFERENCIA",
          required: false,
          files: [],
          delete: false,
          loading: false,
          multiple: false
      },
    ]
  }
}
