<h2 *ngIf="!isDone" class="text-center my-4">AntecipaGov - Cadastrar Fornecedor</h2>
<mat-stepper linear labelPosition="bottom" #stepper *ngIf="!isDone" class="px-4" (selectionChange)="onSelectionChange($event)">
  <mat-step completed="false">
    <ng-template matStepLabel>Empresa</ng-template>
    <app-company (nextStep)="onNext()"></app-company>
  </mat-step>
  <mat-step completed="false">
    <ng-template matStepLabel>Dados Bancários</ng-template>
    <ng-container *ngIf="stepVisited[1]">
      <app-bank (nextStep)="onNext()" (backStep)="onBack()"></app-bank>
    </ng-container>
  </mat-step>

  <mat-step completed="false">
    <ng-template matStepLabel>Endereço</ng-template>
    <ng-container *ngIf="stepVisited[2]">
      <app-address (nextStep)="onNext()" (backStep)="onBack()"></app-address>
    </ng-container>
  </mat-step>
  <mat-step completed="false">
    <ng-template matStepLabel>Contato</ng-template>
    <ng-container *ngIf="stepVisited[3]">
      <app-contact (nextStep)="onNext()" (backStep)="onBack()"></app-contact>
    </ng-container>
  </mat-step>

  <mat-step completed="false">
    <ng-template matStepLabel>Quadro de Atividades</ng-template>
    <ng-container *ngIf="stepVisited[4]">
      <app-activity (nextStep)="onNext()" (backStep)="onBack()"></app-activity>
    </ng-container>
  </mat-step>
  <mat-step completed="false">
    <ng-template matStepLabel>Quadro Societário</ng-template>
    <ng-container *ngIf="stepVisited[5]">
      <app-partner (nextStep)="onNext()" (backStep)="onBack()"></app-partner>
    </ng-container>
  </mat-step>

  <mat-step completed="false">
    <ng-template matStepLabel>Documentos</ng-template>
    <ng-container *ngIf="stepVisited[6]">
      <app-documents (nextStep)="onNext()" (backStep)="onBack()"></app-documents>
    </ng-container>
  </mat-step>

  <mat-step completed="false">
    <ng-template matStepLabel>Finalizar</ng-template>
    <ng-container *ngIf="stepVisited[7]">
      <app-done (isDone)="onDone()"></app-done>
    </ng-container>
  </mat-step>
</mat-stepper>

<app-success-page *ngIf="isDone"></app-success-page>
