<h2 mat-dialog-title>Editar Atividade Econômica</h2>
<mat-dialog-content>
  <form
    [formGroup]="form"
    class="container p-2"
  >
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Código CNAE</mat-label>
    <input
      matInput
      formControlName="code"
      placeholder="00.00-0-00"
      mask="00.00-0-00"
    >
    <mat-error>Código Inválido</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Descrição</mat-label>
    <input
      matInput
      formControlName="name"
      readonly
      matTooltip="Preenchido automaticamente após inserir o código CNAE"
    >
  </mat-form-field>
    <mat-slide-toggle formControlName="mainActivity" class="row my-2">
      CNAE  principal
    </mat-slide-toggle>

  </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between p-2">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button cdkFocusInitial (click)="onSave()" class="bg-broad text-white">Salvar</button>
</mat-dialog-actions>
