import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SharedModule } from '../../../../shared/shared.module';
import { Company } from '../../utils/company';
import { AppMaterialModule } from './../../../../shared/app-material/app-material.module';
import { RegisterService } from '../../services/register.service';
import { ICompany } from '../../../../core/interfaces/ICompany';
import { distinctUntilChanged } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-company',
  standalone: true,
  imports: [AppMaterialModule, SharedModule],
  templateUrl: './company.component.html',
  styleUrl: './company.component.scss'
})
export class CompanyComponent {
  form!: FormGroup;
  natureLegalList = Company.legalNatureList();
  @Output() nextStep = new EventEmitter<void>();

  constructor(
    private formBuilder: FormBuilder,
    private service: RegisterService,
    private snackBar: MatSnackBar,
  ) {
    this.initsForm();
  }

  initsForm() {
    this.form = this.formBuilder.group({
      anualBilling: [{ value: '', disabled: true }, Validators.required],
      cnpj: ['', [Validators.required]],
      legalNature: [{ value: '', disabled: true }, Validators.required],
      legalNatureCode: [''],
      origin: ['SigaPregao'],
      name: [{ value: '', disabled: true }, Validators.required],
      openedOn: [{ value: '', disabled: true }, Validators.required],
      regime: [{ value: '', disabled: true }, Validators.required],
      sharedCapital: [{ value: '', disabled: true }, Validators.required],
      fantasyName: [{ value: '', disabled: true }, Validators.required],
      unit: [{ value: '', disabled: true }, Validators.required],
      site: [{ value: '', disabled: true }]
    });

    this.form.get('cnpj')?.valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe((cnpj: string) => {
        if (this.form.get('cnpj')?.valid) {
          this.getCompanyByCnpj(cnpj);
        }
      });
  }

  setFormsValue(company: ICompany) {
    this.form.patchValue({
      anualBilling: company.anualBilling,
      cnpj: company.cnpj,
      legalNature: company.legalNature,
      name: company.name,
      openedOn: company.openedOn,
      regime: company.regime,
      sharedCapital: company.sharedCapital,
      fantasyName: company.fantasyName,
      unit: company.unit,
      site: company.site
    });
  }

  getCompanyByCnpj(cnpj: string) {
    this.service.getCompanyByCnpj(cnpj).subscribe({
      next: (data) => {
        this.setFormsValue(data);
        this.form.enable();
      },
    });
  }

  postCompany() {
    this.service.postCompany(this.form.value).subscribe({
      next: (companyId) => {
        this.service.setCompanyId(companyId);
        this.nextStep.emit();
      },
    });
  }

  setNatureCode(name: string) {
    const code = this.natureLegalList.find((nature) => nature.name === name)?.code;
    this.form.patchValue({legalNatureCode: code ?? null});
  }

  onNext() {
    if(this.form.invalid) {
      this.snackBar.open('Preencha todos os campos obrigatórios', 'OK');
      return;
    }

    this.setNatureCode(this.form.get('legalNature')?.value);

    this.postCompany();
  }
}
