import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { SharedModule } from '../../../../shared/shared.module';
import { IUploadCard } from '../../../../core/interfaces/IUploadCard';

@Component({
  selector: 'app-upload-card',
  standalone: true,
  imports: [AppMaterialModule, SharedModule],
  templateUrl: './upload-card.component.html',
  styleUrl: './upload-card.component.scss'
})
export class UploadCardComponent {
  @Input() item!:IUploadCard;
  @Input() showError = false;
  @Output() add = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();


  addDocument(event: any) {
    this.add.emit(event);
  }

  removeDocument(index: number) {
    this.remove.emit(index);
  }
}
