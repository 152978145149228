import { Component, inject } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { SharedModule } from '../../../../shared/shared.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IBank } from '../../../../core/interfaces/IBank';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BankList } from '../../utils/bank-list';
import { WithDigitDirective } from '../../directives/withDigit.directive';

@Component({
  selector: 'app-bank-edit-modal',
  standalone: true,
  imports: [AppMaterialModule, SharedModule, WithDigitDirective],
  templateUrl: './bank-edit-modal.component.html',
  styleUrl: './bank-edit-modal.component.scss'
})
export class BankEditModalComponent {
  readonly dialogRef = inject(MatDialogRef<BankEditModalComponent>);
  readonly data = inject<{bank:IBank, bankList:IBank[]}>(MAT_DIALOG_DATA);
  readonly formBuilder = inject(FormBuilder);
  readonly snackBar = inject(MatSnackBar);
  form!: FormGroup;
  bankList = BankList.list();

  constructor() {
    const bank = this.bankList.find(bank => bank.COMPE === this.data.bank.code);

    this.form = this.formBuilder.group({
      account: [this.data.bank.account, Validators.required],
      agency: [this.data.bank.agency, Validators.required],
      bank: [bank, Validators.required],
      code: [this.data.bank.code],
      name: [this.data.bank.name],
      pix: [this.data.bank.pix],
      type: ['CORRENTE'],
      purpose: ['REFERENCE'],
      mainAccount: [this.data.bank.mainAccount],
    });
  }

  onSave(){
    if(this.form.invalid){
      this.snackBar.open('Preencha todos os campos.', 'OK');
      return;
    }

    if(this.form.value.mainAccount && this.hasMainBank()){
      this.snackBar.open('Já existe uma conta bancária principal.', 'OK');
      return;
    }

    this.setBank();
    this.formatAccountAndAgency();

    this.dialogRef.close(this.form.value);
  }

  setBank(){
    this.form.patchValue({
      name: this.form.value.bank.ShortName,
      code: this.form.value.bank.COMPE
    });

    delete this.form.value.bank;
  }

  hasMainBank () {
    return !!this.data.bankList.find(bank => bank.mainAccount);
  }

  formatAccountAndAgency(){
    this.form.patchValue({
      account: this.form.value.account.replace(/-/g, ''),
      agency: this.form.value.agency.replace(/-/g, ''),
    })
  }
}
