import { Component, EventEmitter, inject, Output } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { SharedModule } from '../../../../shared/shared.module';
import { Form, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { IContact } from '../../../../core/interfaces/IContact';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegisterService } from '../../services/register.service';
import { MatDialog } from '@angular/material/dialog';
import { ContactEditModalComponent } from '../../components/contact-edit-modal/contact-edit-modal.component';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [AppMaterialModule, SharedModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent {
  form!: FormGroup;
  dataSource:IContact[] = [];
  displayedColumns = [
    'action',
    'name',
    'mail',
    'phone',
    'role',
  ];
  readonly dialog = inject(MatDialog);
  @Output() nextStep = new EventEmitter<void>();
  @Output() backStep = new EventEmitter<void>();

  constructor(
    private formBuilder: FormBuilder,
    private snackBar:MatSnackBar,
    private service: RegisterService
  ) {
    this.form = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      mail: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      role: ['', Validators.required],
      whatsapp: [false],
      mainContact: [false]
    });

    this.getContactList();
  }
  OnNext(){}

  getContactList() {
    this.service.getContactList().subscribe({
      next: (data) => {
        this.dataSource = data;
      },
      error: (error) => {
        this.snackBar.open('Erro ao carregar contatos.', 'OK');
      }
    });
  }

  addContact(formDirective: FormGroupDirective) {
    if (!this.form.valid) {
      this.snackBar.open('Preencha todos os campos obrigatórios.', 'OK');
      return;
    }

    if(this.form.value.mainContact && this.hasMainContact()) {
      this.snackBar.open('Já existe um contato principal.', 'OK');
      return
    }


    this.service.postContact(this.form.value).subscribe({
      next: (contactAdded) => {
        this.snackBar.open('Contato cadastrado com sucesso.', 'OK');
        this.dataSource = [...this.dataSource, contactAdded];
        this.form.reset();
        formDirective.resetForm();
      }
    });
  }

  deleteContact(contact: IContact, index: number) {
    this.service.deleteContact(contact.id).subscribe({
      next: () => {
        this.snackBar.open('Contato excluído com sucesso.', 'OK');
        this.dataSource.splice(index, 1);
        this.dataSource = [...this.dataSource];
      }
    });
  }

  editContact(contact: IContact, index: number) {
    const dialogRef = this.dialog.open(ContactEditModalComponent, {data: {contact: contact, contactList: this.dataSource}});

    dialogRef.afterClosed().subscribe(contactEdited => {
      if (!contactEdited) return;

      this.service.editContact(contact.id, contactEdited).subscribe({
        next: () => {
          this.snackBar.open('Contato editado com sucesso.', 'OK');
          this.dataSource[index] = contactEdited;
          this.dataSource = [...this.dataSource];
        }
      });
    });
  }

  hasMainContact () {
    return !!this.dataSource.find(contact => contact.mainContact);
  }

  onNext() {
    if(!this.dataSource.length){
      this.snackBar.open('Adicione ao menos um contato.', 'OK');
      return;
    }

    this.nextStep.emit();
  }

  onBack() {
    this.backStep.emit();
  }
}

