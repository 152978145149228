<form
  [formGroup]="form"
  class="container p-2"
>
  <div class="row">
    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>CEP</mat-label>
      <input
        matInput
        formControlName="postalCode"
        placeholder="00000-000"
        mask="00000-000"
        (ngModelChange)="getAddressByCnpj($event)"
      >
      <mat-error>CEP inválido </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Número</mat-label>
      <input matInput required formControlName="number">
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Complemento</mat-label>
      <input matInput formControlName="additionalInformation">
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field appearance="outline" class="col">
      <mat-label>Endereço</mat-label>
      <input matInput required  formControlName="street">
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field appearance="outline" class="col-md-3">
      <mat-label>Bairro</mat-label>
      <input matInput required formControlName="district">
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-3">
      <mat-label>Cidade</mat-label>
      <input matInput required formControlName="city">
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-3">
      <mat-label>Estado</mat-label>
      <input matInput required formControlName="state">
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-3">
      <mat-label>País</mat-label>
      <input matInput required formControlName="country">
    </mat-form-field>
  </div>

  <div class="d-flex justify-content-between">
    <button mat-flat-button type="button" (click)="onBack()">
      Voltar
    </button>
    <button mat-flat-button class="bg-broad text-white" type="submit" (click)="onNext()">
      Avançar
    </button>
  </div>
</form>
