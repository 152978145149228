import { Component, EventEmitter, inject, Output } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { SharedModule } from '../../../../shared/shared.module';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { IActivity } from '../../../../core/interfaces/IActivity';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegisterService } from '../../services/register.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivityEditModalComponent } from '../../components/activity-edit-modal/activity-edit-modal.component';

@Component({
  selector: 'app-activity',
  standalone: true,
  imports: [AppMaterialModule, SharedModule],
  templateUrl: './activity.component.html',
  styleUrl: './activity.component.scss'
})
export class ActivityComponent {
  form!: FormGroup;
  dataSource:IActivity[] = [];
  @Output() nextStep = new EventEmitter<void>();
  @Output() backStep = new EventEmitter<void>();
  readonly formBuilder = inject(FormBuilder);
  readonly snackBar = inject(MatSnackBar);
  readonly service = inject(RegisterService);
  readonly dialog = inject(MatDialog);

  displayedColumns = [
    'action',
    'code',
    'name',
    'mainActivity'
  ];


  constructor() {
    this.initForm();
    this.getActivities();
  }

  initForm() {
    this.form = this.formBuilder.group({
      code: ['', Validators.required],
      name: [''],
      mainActivity: [false],
    });

    this.form.get('code')?.valueChanges.subscribe((code) => {
      if(this.form.get('code')?.invalid) return;

      this.service.getEconomicActivitiesByCNAE(code).subscribe({
        next: (activity) => {
          if(!activity.length) {
            this.form.get('code')?.setErrors({invalid: true});
            return;
          }

          this.form.patchValue({name: activity[0].classe.descricao});
        },
        error: (error) => {
          this.snackBar.open('Ocorreu um erro ao buscar as informações do CNAE.', 'OK');
        }
      });
    });
  }

  getActivities() {
    this.service.getEconomicActivities().subscribe({
      next: (activities) => {
        this.dataSource = activities;
      },
      error: () => {
        this.snackBar.open('Ocorreu um erro ao buscar as atividades econômicas.', 'OK');
      }
    });
  }

  addActivity(formDirective: FormGroupDirective) {
    if (!this.form.valid) {
      this.snackBar.open('Preencha todos os campos obrigatórios.', 'OK');
      return;
    }

    if(this.form.value.mainActivity && this.hasMainActivity()) {
      this.snackBar.open('Já existe uma atividade principal.', 'OK');
      return;
    }

    this.service.postEconomicActivities(this.form.value).subscribe({
      next: (activity) => {
        this.dataSource = [...this.dataSource, activity];
        this.snackBar.open('Atividade econômica cadastrada com sucesso.', 'OK');
      },
    });

    this.form.reset();
    formDirective.resetForm();
  }

  deleteActivity(index: number) {
    this.service.deleteEconomicActivities(this.dataSource[index]).subscribe({
      next: () => {
        this.dataSource.splice(index, 1);
        this.dataSource = [...this.dataSource];
        this.snackBar.open('Atividade econômica excluída com sucesso.', 'OK');
      },
      error: () => {
        this.snackBar.open('Ocorreu um erro ao excluir a atividade econômica.', 'OK');
      }
    });
  }

  editActivity(index: number) {
    const dialogRef = this.dialog.open(
      ActivityEditModalComponent,
      {data: {activity: this.dataSource[index], activityList: this.dataSource}}
    );

    dialogRef.afterClosed().subscribe((result: IActivity) => {
      if (!result) return;

      this.service.putEconomicActivities(result).subscribe({
        next: () => {
          this.snackBar.open('Atividade econômica editada com sucesso.', 'OK');
          this.dataSource[index] = result;
          this.dataSource = [...this.dataSource];
        }
      });

    });
  }

  hasMainActivity () {
    return !!this.dataSource.find(activity => activity.mainActivity);
  }

  onNext() {
    if(!this.dataSource.length) {
      this.snackBar.open('Adicione ao menos uma atividade econômica.', 'OK');
      return;
    }

    this.nextStep.emit();
  }

  onBack() {
    this.backStep.emit();
  }
}
