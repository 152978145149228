import { Component, EventEmitter, inject, Output } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { SharedModule } from '../../../../shared/shared.module';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { IPartner } from '../../../../core/interfaces/IPartner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegisterService } from '../../services/register.service';
import { MatDialog } from '@angular/material/dialog';
import { Partner } from '../../utils/partner';
import { PartnerEditModalComponent } from '../../components/partner-edit-modal/partner-edit-modal.component';
import { UploadCardComponent } from '../../components/upload-card/upload-card.component';
import { delay, forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-partner',
  standalone: true,
  imports: [AppMaterialModule, SharedModule, UploadCardComponent],
  templateUrl: './partner.component.html',
  styleUrl: './partner.component.scss'
})
export class PartnerComponent {
  form!: FormGroup;
  dataSource:IPartner[] = [];
  qualificationList = Partner.qualificationList();
  uploadList = Partner.uploadCardList();
  @Output() nextStep = new EventEmitter<void>();
  @Output() backStep = new EventEmitter<void>();
  readonly formBuilder = inject(FormBuilder);
  readonly snackBar = inject(MatSnackBar);
  readonly service = inject(RegisterService);
  readonly dialog = inject(MatDialog);

  displayedColumns = [
    'action',
    'name',
    'cpfCnpj',
    'phone',
    'mail',
    'qualificationDescrition'
  ]

  constructor() {
    this.initForm();
    this.getPartners();
  }

  initForm() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      cpf: ['', Validators.required],
      phone: ['', Validators.required],
      mail: ['', [Validators.required, Validators.email]],
      qualificationCode: ['', Validators.required],
      isSolidaryPartner: [false],
      isSubscriber: [false]
    });
  }

  getPartners() {
    this.service.getPartners().subscribe({
      next: (partners) => {
        this.dataSource = partners;
      }
    });
  }

  addPartner(formDirective: FormGroupDirective) {
    if(this.form.invalid) {
      this.snackBar.open('Preencha todos os campos corretamente.', 'OK');
      return;
    }

    if(!this.uploadList.every((item) => item.files.length)) {
      this.snackBar.open('Upload dos documentos é obrigatório.', 'OK');
      return;
    }

    if(this.form.value.isSubscriber && this.dataSource.some(partner => partner.subscriber)) {
      this.snackBar.open('Já existe um sócio assinante.', 'OK');
      return;
    }

    this.service.postPartner(this.form.value).subscribe({
      next: (partner) => {
        this.dataSource = [...this.dataSource, partner];
        this.snackBar.open('Sócio adicionado com sucesso.', 'OK');
        formDirective.resetForm();
        this.form.reset();
        this.uploadDocuments(partner.id);
      }
    });
  }

  editPartner(index: number) {
    const dialogRef = this.dialog.open(
      PartnerEditModalComponent,
      {data: {partner: this.dataSource[index], partnerList: this.dataSource}}
    );

    dialogRef.afterClosed().subscribe((result) => {
      if(!result) return;

      this.service.putPartner(result).subscribe({
        next: (partner) => {
          this.dataSource[index] = partner;
          this.dataSource = [...this.dataSource];
          this.snackBar.open('Sócio editado com sucesso.', 'OK');
        }
      });
    });
  }

  deletePartner(index: number) {
    this.service.deletePartner(this.dataSource[index]).subscribe({
      next: () => {
        this.dataSource.splice(index, 1);
        this.dataSource = [...this.dataSource];
        this.snackBar.open('Sócio removido com sucesso.', 'OK');
      }
    });
  }

  onNext() {
    if(!this.dataSource.length) {
      this.snackBar.open('Adicione ao menos um sócio.', 'OK');
      return;
    }

    this.nextStep.emit();
  }

  onBack() {
    this.backStep.emit();
  }

  addDocument(event: any, index: number) {
    const files = <FileList>event.target.files;
    this.uploadList[index].files = [...Array.from(files)];
  }

  uploadDocuments(id:string) {
    const observables = this.uploadList.map(document => {
      if (document.files.length) {
        return this.service.addPartnerDocuments(document.files, document.type, id);
      }
      return of(null);
    });

    forkJoin(observables).subscribe({
      error: () => {
        this.snackBar.open('Erro ao realizar upload de documentos', 'OK');
      }
    });
  }
}
