<mat-card
  *ngIf="!!item"
  appearance="outlined"
  class="mb-2"
  [ngClass]="{'error': showError && !item.files.length}"
>
  <mat-card-content class="d-flex align-items-center">
    <button
      mat-button
      class="me-4 bg-broad text-white p-4" type="button"
      (click)="fileInput.click()"
    >
      <mat-icon>cloud_upload</mat-icon>
      <span>Importar</span>
      <input
        class="d-none" type="file"
        #fileInput
        accept="'.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.pdf', '.PDF'"
        (change)="addDocument($event)"
        [multiple]="item.multiple"
      >
    </button>
    <div class="d-flex flex-column">
      <span class="d-flex ">
        <b>{{ item.description }}</b>
        <small *ngIf="item.required">*</small>
        <mat-icon class="ms-2 text-muted" *ngIf="!!item.tooltip" [matTooltip]="item.tooltip">help</mat-icon>
      </span>
      <small class="d-flex" *ngFor="let file of item.files; let i = index">
        <mat-icon color="warn" style="cursor: pointer;" (click)="removeDocument(i)" *ngIf="item.delete">
          remove
        </mat-icon>
        {{ file.name }}
      </small>
    </div>
  </mat-card-content>
</mat-card>
