import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ICompany } from '../../../core/interfaces/ICompany';
import { IAddress, IAddressViaCep } from '../../../core/interfaces/IAddress';
import { IContact } from '../../../core/interfaces/IContact';
import { IBank } from '../../../core/interfaces/IBank';
import { IActivity } from '../../../core/interfaces/IActivity';
import { IPartner } from '../../../core/interfaces/IPartner';
import { IDocument } from '../../../core/interfaces/IDocument';
import { Observable } from 'rxjs';
import { IDone } from '../../../core/interfaces/IDone';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  public companyId: string = '';

  constructor(private httpClient: HttpClient) { }

  getCompanyId() {
    return this.companyId;
  }

  setCompanyId(companyId: string) {
    this.companyId = companyId;
  }

  getCompanyByCnpj(cnpj: string) {
    return this.httpClient.get<ICompany>(environment.API_url + '/open/company/register/' + cnpj);
  }

  postCompany(data: ICompany) {
    return this.httpClient.post<string>(environment.API_url+ '/open/company/register/', data);
  }

  postContact(contact: IContact) {
    return this.httpClient.post<IContact>(environment.API_url + '/open/company/' + this.companyId + '/contacts', contact);
  }

  getContactList() {
    return this.httpClient.get<IContact[]>(environment.API_url + '/open/company/' + this.companyId + '/contacts');
  }

  deleteContact(contactId: string) {
    return this.httpClient.delete(environment.API_url + '/open/company/' + this.companyId + '/contacts/' + contactId);
  }

  editContact(contactId: string, contact: IContact) {
    return this.httpClient.put(environment.API_url + '/open/company/' + this.companyId + '/contacts/' + contactId, contact);
  }

  putAddress(address: IAddress) {
    return this.httpClient.put(environment.API_url + '/open/company/' + this.companyId + '/address', address);
  }

  getAddress() {
    return this.httpClient.get<IAddress>(environment.API_url + '/open/company/' + this.companyId + '/address');
  }

  getAddressByCep(cep: string) {
    return this.httpClient.get<IAddressViaCep>(`https://viacep.com.br/ws/${cep}/json/`);
  }

  getBanks() {
    return this.httpClient.get<IBank[]>(environment.API_url + '/open/company/' + this.companyId + '/bank');
  }

  postBanks(banks: IBank[]) {
    return this.httpClient.post(environment.API_url + '/open/company/' + this.companyId + '/bank', banks);
  }

  putBanks(banks: IBank[]) {
    return this.httpClient.put(environment.API_url + '/open/company/' + this.companyId + '/bank', banks);
  }

  postEconomicActivities(activities: IActivity) {
    return this.httpClient.post<IActivity>(environment.API_url + '/open/company/' + this.companyId + '/activities', activities);
  }

  getEconomicActivities() {
    return this.httpClient.get<IActivity[]>(environment.API_url + '/open/company/' + this.companyId + '/activities');
  }

  getEconomicActivitiesByCNAE(CNAE:string) {
    return this.httpClient.get<any>('https://servicodados.ibge.gov.br/api/v2/cnae/subclasses/' + CNAE);
  }

  deleteEconomicActivities(activities: IActivity) {
    return this.httpClient.delete(environment.API_url + '/open/company/' + this.companyId + '/activities/' +  activities.id);
  }

  putEconomicActivities(activities: IActivity) {
    return this.httpClient.put(environment.API_url + '/open/company/' + this.companyId + '/activities/' +  activities.id, activities);
  }

  getPartners() {
    return this.httpClient.get<IPartner[]>(environment.API_url + '/open/company/' + this.companyId + '/partner');
  }

  postPartner(partner: IPartner) {
    return this.httpClient.post<IPartner>(environment.API_url + '/open/company/' + this.companyId + '/partner', partner);
  }

  putPartner(partner: IPartner) {
    return this.httpClient.put<IPartner>(environment.API_url + '/open/company/' + this.companyId + '/partner/' + partner.id, partner);
  }

  deletePartner(partner: IPartner) {
    return this.httpClient.delete(environment.API_url + '/open/company/' + this.companyId + '/partner/' + partner.id);
  }

  addPartnerDocuments(files:any[], type: string, partnerID: string){
    const formData = new FormData();
    files.forEach(file => {
      formData.append('file', file, file.name);
      formData.append('name', file.name);
    });

    formData.append('documentType', type);

    return this.httpClient.post(
      environment.API_url + '/open/company/' + this.companyId + '/partner/' + partnerID + '/document/',
      formData
    );
  }

  getPartnerDocuments(partnerID: string){
    return this.httpClient.post<IDocument[]>(
      environment.API_url + '/open/company/' + this.companyId + '/partner/' + partnerID + '/document/get',
      {
        "status": [
          "PENDING"
        ]
      }
    );
  }

  getCompanyDocuments () {
    return this.httpClient.post<IDocument[]>(
      environment.API_url + '/open/company/' + this.companyId + '/documents/getAll',
      {
        "status": [
          "PENDING"
        ]
      }
    );
  }

  addCompanyDocuments(files:any[], type: string) : Observable<IDocument> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('file', file, file.name);
      formData.append('name', file.name);
    });

    formData.append('documentType', type);

    return this.httpClient.post<IDocument>(
      environment.API_url + '/open/company/' + this.companyId + '/documents/',
      formData
    );
  }

  postSignup(signup: IDone) {
    return this.httpClient.post<string>(environment.API_url + '/open/company/register/' + this.companyId + '/signup', signup);
  }
}
