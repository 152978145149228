<form
  class="container p-2 d-flex flex-column align-items-center"
  [formGroup]="form"
>
  <div class="my-3 w-75 text-start">
    <h1 class="text-primary">Você está   quase lá!</h1>
    <small>Por gentilieza crie um login e senha, para acessar a plataforma.  </small>
  </div>
  <mat-form-field appearance="outline" class="w-75">
    <mat-label>E-mail</mat-label>
    <input matInput placeholder="" formControlName="mail">
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-75">
    <mat-label>Senha</mat-label>
    <input matInput formControlName="password" [type]="hide() ? 'password' : 'text'">
    <button
      type="button"
      mat-icon-button
      matSuffix
      (click)="clickEvent($event)"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="hide()"
    >
      <mat-icon>{{hide() ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-75">
    <mat-label>Senha</mat-label>
    <input matInput formControlName="passwordConfirm" [type]="hide() ? 'password' : 'text'">
    <button
      type="button"
      mat-icon-button
      matSuffix
      (click)="clickEvent($event)"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="hide()"
    >
      <mat-icon>{{hide() ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
  </mat-form-field>
  <div class="d-flex flex-column align-items-start w-75">
    <small class="my-2"><b>Para garantir seu acesso à nossa plataforma, precisamos que você leia e concorde com os termos abaixo:</b></small>
    <mat-checkbox formControlName="bacenAccepted">Autorizo a consulta de informações junto ao Banco Central (BACEN).</mat-checkbox>
    <mat-checkbox formControlName="pepAccepted">Declaro que não sou uma Pessoa Politicamente Exposta (PEP).</mat-checkbox>
  </div>
  <div class="d-flex justify-content-end w-75 my-2">
    <button mat-flat-button class="bg-broad text-white p-4" type="submit" (click)="onDone()">
      Finalizar
    </button>
  </div>
</form>


