import { Component, EventEmitter, inject, Output } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { SharedModule } from '../../../../shared/shared.module';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { IBank } from '../../../../core/interfaces/IBank';
import { BankList } from '../../utils/bank-list';
import { RegisterService } from '../../services/register.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { BankEditModalComponent } from '../../components/bank-edit-modal/bank-edit-modal.component';
import { WithDigitDirective } from '../../directives/withDigit.directive';

@Component({
  selector: 'app-bank',
  standalone: true,
  imports: [AppMaterialModule, SharedModule, WithDigitDirective],
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss']
})
export class BankComponent {
  form!: FormGroup;
  dataSource:IBank[] = [];
  bankList = BankList.list();
  displayedColumns = [
    'action',
    'name',
    'agency',
    'account',
    'pix',
    /* 'cnpj', */
  ];
  @Output() nextStep = new EventEmitter<void>();
  @Output() backStep = new EventEmitter<void>();
  readonly service = inject(RegisterService);
  readonly formBuilder = inject(FormBuilder);
  readonly snackBar = inject(MatSnackBar);
  readonly dialog = inject(MatDialog);

  constructor() {
    this.form = this.formBuilder.group({
      account: ['', Validators.required],
      agency: ['', Validators.required],
      bank: ['', Validators.required],
      code: [''],
      name: [''],
      pix: [''],
      type: ['CORRENTE'],
      purpose: ['REFERENCE'],
      /* cnpj: ['', Validators.required], */
      mainAccount: [false],
    });

    this.getBankList();
  }


  addBank(formDirective: FormGroupDirective) {
    if (!this.form.valid) {
      this.snackBar.open('Preencha todos os campos obrigatórios.', 'OK');
      return;
    }

    if(this.form.value.mainAccount && this.hasMainBank()){
      this.snackBar.open('Já existe uma conta bancária principal.', 'OK');
      return;
    }

    this.form.patchValue({
      name: this.form.value.bank.ShortName,
      code: this.form.value.bank.COMPE
    });

    this.formatAccountAndAgency();

    delete this.form.value.bank;

    this.dataSource = [...this.dataSource, this.form.value];

    this.form.reset();
    formDirective.resetForm();
    this.form.patchValue({ type: 'CORRENTE', purpose: 'REFERENCE' });
  }

  deleteBank(index: number) {
    this.dataSource.splice(index, 1);
    this.dataSource = [...this.dataSource];
  }

  editBank(index: number) {
    const dialogRef = this.dialog.open(BankEditModalComponent, {
      data: {bank:this.dataSource[index], bankList: this.dataSource},
    });

    dialogRef.afterClosed().subscribe((result: IBank) => {
      if (!result) return;

      this.dataSource[index] = result;
      this.dataSource = [...this.dataSource];
    });
  }

  saveBankList() {
    this.service.putBanks(this.dataSource).subscribe({
      next: () => {
        this.snackBar.open('Bancos salvos com sucesso.', 'OK');
        this.nextStep.emit();
      },
      error: () => {
        this.snackBar.open('Erro ao salvar bancos.', 'OK');
      }
    });
  }

  getBankList() {
    this.service.getBanks().subscribe({
      next: (data) => {
        this.dataSource = data;
      },
      error: () => {
        this.snackBar.open('Erro ao buscar bancos.', 'OK');
      }
    });
  }

  hasMainBank () {
    return !!this.dataSource.find(bank => bank.mainAccount);
  }

  formatAccountAndAgency(){
    this.form.patchValue({
      account: this.form.value.account.replace(/-/g, ''),
      agency: this.form.value.agency.replace(/-/g, ''),
    })
  }

  onNext(){
    if(!this.dataSource.length) {
      this.snackBar.open('Adicione ao menos um banco.', 'OK');
      return;
    }

    this.saveBankList();
  }

  onBack(){
    this.backStep.emit();
  }
}
