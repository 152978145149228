<div class="container">
  <b class="d-block mb-2">Cadastrar Atividade Econômica</b>
  <form
    [formGroup]="form"
    #formDirective="ngForm"
    (ngSubmit)="addActivity(formDirective)"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>Código CNAE</mat-label>
        <input
          matInput
          formControlName="code"
          placeholder="00.00-0-00"
          mask="00.00-0-00"
        >
        <mat-error>Código Inválido</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-8">
        <mat-label>Descrição</mat-label>
        <input
          matInput
          formControlName="name"
          readonly
          matTooltip="Preenchido automaticamente após inserir o código CNAE"
        >
      </mat-form-field>
    </div>

    <mat-slide-toggle formControlName="mainActivity" class="row my-2">
      CNAE  principal
    </mat-slide-toggle>

    <div class="row d-flex justify-content-end">
      <button type="submit" mat-flat-button class="col-md-2 bg-broad text-white">
        Adicionar
      </button>
    </div>
  </form>

  <section class="overflow">
    <b class="d-block mb-2">CNAEs  cadastrados</b>
    <table mat-table [dataSource]="dataSource" class="mb-5">
      <!-- Action -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-center">
         Ações
        </th>
        <td mat-cell *matCellDef="let row" class="text-center">
         <button type="button" mat-icon-button (click)="editActivity(dataSource.indexOf(row))">
            <mat-icon>edit</mat-icon>
         </button>
         <button type="button" mat-icon-button (click)="deleteActivity(dataSource.indexOf(row))">
          <mat-icon>delete</mat-icon>
       </button>
        </td>
      </ng-container>

      <!-- CNAE -->
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> CNAE </th>
        <td mat-cell *matCellDef="let element"> {{element.code}} </td>
      </ng-container>

      <!-- Descrição -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Descrição </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Principal -->
      <ng-container matColumnDef="mainActivity">
        <th mat-header-cell *matHeaderCellDef> Principal </th>
        <td mat-cell *matCellDef="let element">{{element.mainActivity ? 'Sim' : 'Não'}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </section>

  <div class="d-flex justify-content-between">
    <button mat-button type="button" (click)="onBack()">
      voltar
    </button>
    <button mat-flat-button class="bg-broad text-white" type="button" (click)="onNext()">
      Avançar
    </button>
  </div>
</div>
