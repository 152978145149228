<div class="container">
  <b class="d-block mb-2">Cadastrar Sócio</b>
  <form
    [formGroup]="form"
    #formDirective="ngForm"
    (ngSubmit)="addPartner(formDirective)"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>Nome</mat-label>
        <input
          matInput
          formControlName="name"
        >
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>E-mail</mat-label>
        <input
          matInput
          formControlName="mail"
        >
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>CPF</mat-label>
        <input
          matInput
          formControlName="cpf"
          mask="000.000.000-00"
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>Qualificação</mat-label>
        <mat-select  formControlName="qualificationCode">
          @for (qualification of qualificationList; track $index) {
            <mat-option [value]="qualification.code">{{ qualification.code }} - {{qualification.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>Celular</mat-label>
        <input
          matInput
          formControlName="phone"
          mask="(00) 00000-0009"
        >
      </mat-form-field>
    </div>

    <mat-slide-toggle formControlName="isSubscriber" class="d-block">
      Assinante: Pessoa responsável pela empresa conforme contrato social. (Deve possuir E-CPF)
    </mat-slide-toggle>
    <mat-slide-toggle formControlName="isSolidaryPartner" class="d-block my-3">
      Sócio Solidário
    </mat-slide-toggle>

    <section class="row my-5">
      <app-upload-card
        *ngFor="let item of uploadList; let i = index;"
        [item]="item"
        class="col-md-6"
        (add)="addDocument($event, i)"
      >
      </app-upload-card>
    </section>

    <div class="row d-flex justify-content-end mb-3">
      <button type="submit" mat-flat-button class="col-md-2 bg-broad text-white">
        Adicionar
      </button>
    </div>
  </form>

  <section class="overflow">
    <b class="d-block mb-2">Sócios Cadastrados</b>
    <table mat-table [dataSource]="dataSource" class="mb-5">
      <!-- Action -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-center">
         Ações
        </th>
        <td mat-cell *matCellDef="let row" class="text-center">
         <button type="button" mat-icon-button (click)="editPartner(dataSource.indexOf(row))">
            <mat-icon>edit</mat-icon>
         </button>
         <button type="button" mat-icon-button (click)="deletePartner(dataSource.indexOf(row))">
          <mat-icon>delete</mat-icon>
       </button>
        </td>
      </ng-container>

      <!-- Nome -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- CPF -->
      <ng-container matColumnDef="cpfCnpj">
        <th mat-header-cell *matHeaderCellDef> CPF </th>
        <td mat-cell *matCellDef="let element"> {{element.cpfCnpj}} </td>
      </ng-container>

      <!-- Celular -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Celular </th>
        <td mat-cell *matCellDef="let element">{{element.phone }}</td>
      </ng-container>

      <!-- E-mail -->
      <ng-container matColumnDef="mail">
        <th mat-header-cell *matHeaderCellDef> E-mail </th>
        <td mat-cell *matCellDef="let element">{{element.mail }}</td>
      </ng-container>

      <!-- Qualificação -->
      <ng-container matColumnDef="qualificationDescrition">
        <th mat-header-cell *matHeaderCellDef> Qualificação </th>
        <td mat-cell *matCellDef="let element">{{element.qualificationDescrition }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </section>

  <div class="d-flex justify-content-between">
    <button mat-button type="button" (click)="onBack()">
      voltar
    </button>
    <button mat-flat-button class="bg-broad text-white" type="button" (click)="onNext()">
      Avançar
    </button>
  </div>
</div>
