import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPartner } from '../../../../core/interfaces/IPartner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegisterService } from '../../services/register.service';
import { SharedModule } from '../../../../shared/shared.module';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { Partner } from '../../utils/partner';
import { UploadCardComponent } from '../upload-card/upload-card.component';

@Component({
  selector: 'app-partner-edit-modal',
  standalone: true,
  imports: [AppMaterialModule, SharedModule, UploadCardComponent],
  templateUrl: './partner-edit-modal.component.html',
  styleUrl: './partner-edit-modal.component.scss'
})
export class PartnerEditModalComponent {
  readonly dialogRef = inject(MatDialogRef<PartnerEditModalComponent>);
  readonly data = inject<{partner:IPartner, partnerList: IPartner[]}>(MAT_DIALOG_DATA);
  readonly formBuilder = inject(FormBuilder);
  readonly snackBar = inject(MatSnackBar);
  readonly service = inject(RegisterService);
  form!: FormGroup;
  qualificationList = Partner.qualificationList();
  uploadList = Partner.uploadCardList();


  constructor() {
    this.form = this.formBuilder.group({
      id: [this.data.partner.id],
      name: [this.data.partner.name, Validators.required],
      cpfCnpj: [this.data.partner.cpfCnpj, Validators.required],
      phone: [this.data.partner.phone, Validators.required],
      mail: [this.data.partner.mail, [Validators.required, Validators.email]],
      qualificationCode: [this.data.partner.qualificationCode, Validators.required],
      isSolidaryPartner: [this.data.partner.solidaryPartner],
      isSubscriber: [this.data.partner.subscriber]
    });

    this.getDocuments();
  }

  onSave() {
    if(this.form.invalid){
      this.snackBar.open('Preencha todos os campos.', 'OK');
      return;
    }

    if(!this.uploadList.every((item) => item.files.length)) {
      this.snackBar.open('Upload dos documentos é obrigatório.', 'OK');
      return;
    }

    if(this.form.value.isSubscriber && this.data.partnerList.some(partner => partner.subscriber)) {
      this.snackBar.open('Já existe um sócio assinante.', 'OK');
      return;
    }

    this.dialogRef.close(this.form.value);
  }

  addDocument(event: any, index: number) {
    const files = <FileList>event.target.files;
    this.uploadList[index].files = [...Array.from(files)];

    this.service.addPartnerDocuments(
      this.uploadList[index].files,
      this.uploadList[index].type,
      this.data.partner.id
    ).subscribe({});
  }

  getDocuments() {
    this.service.getPartnerDocuments(this.data.partner.id).subscribe({
      next: (documents) => {
        this.uploadList.forEach(upload => {
          upload.files = documents.filter((doc) => !!doc.file && (doc.type === upload.type)).map((doc:any) => doc);
        })
      }
    });
  }
}
