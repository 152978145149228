import { RegisterService } from './../../services/register.service';
import { Component, EventEmitter, Inject, inject, Output } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { SharedModule } from '../../../../shared/shared.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [AppMaterialModule, SharedModule],
  templateUrl: './address.component.html',
  styleUrl: './address.component.scss'
})
export class AddressComponent {
  form!: FormGroup;
  @Output() nextStep = new EventEmitter<void>();
  @Output() backStep = new EventEmitter<void>();
  readonly service = inject(RegisterService);
  readonly formBuilder = inject(FormBuilder);
  readonly snackBar = inject(MatSnackBar);

  constructor() {
    this.form = this.formBuilder.group({
      postalCode: ['', [Validators.required]],
      street: [{ value: '', disabled: true }, Validators.required],
      number: [{ value: '', disabled: true }, Validators.required],
      additionalInformation: [{ value: '', disabled: true }],
      district: [{ value: '', disabled: true }, Validators.required],
      city: [{ value: '', disabled: true }, Validators.required],
      state: [{ value: '', disabled: true }, Validators.required],
      country: [{ value: '', disabled: true }, Validators.required]
    });

    this.getAddress();
  }

  getAddress() {
    this.service.getAddress().subscribe((response) => {
      this.form.patchValue({
        postalCode: response.postalCode,
        street: response.street	,
        district: response.district,
        city: response.city,
        state: response.state,
        country: response.country,
        number: response.number,
        additionalInformation: response.additionalInformation
      });

      this.form.enable();
    });
  }

  getAddressByCnpj(cep: string) {
    if(this.form.get('postalCode')?.invalid) return;

    this.service.getAddressByCep(cep).subscribe((response) => {
      if(response.erro) {
        this.form.get('postalCode')?.setErrors({ invalid: true });
        return;
      }

      this.form.patchValue({
        street: response.logradouro	,
        district: response.bairro,
        city: response.localidade,
        state: response.estado,
        country: 'Brasil',
        number: '',
        additionalInformation: ''
      });

      this.form.enable();
    })
  }

  editAddress() {
    this.service.putAddress(this.form.value).subscribe({
      next: () => {
        this.snackBar.open('Endereço salvo com sucesso', 'OK');
        this.nextStep.emit();
      }
    });
  }

  onNext() {
    if(this.form.invalid) {
      this.snackBar.open('Preencha todos os campos obrigatórios', 'OK');
      return;
    }

    this.editAddress();
  }

  onBack() {
    this.backStep.emit();
  }
}
