<div class="container">
  <mat-tab-group class="container">
    <mat-tab label="Documentos Obrigatórios">
      <div *ngFor="let item of uploadList; let i = index;">
        <app-upload-card
          *ngIf="item.required"
          [item]="item"
          (add)="addDocument($event, i)"
        >
        </app-upload-card>
      </div>
    </mat-tab>
    <mat-tab label="Documentos Desejáveis ">
      <div *ngFor="let item of uploadList; let i = index;">
        <app-upload-card
          *ngIf="!item.required"
          [item]="item"
          (add)="addDocument($event, i)"
        >
        </app-upload-card>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="d-flex justify-content-between">
    <button mat-button type="button" (click)="onBack()">
      voltar
    </button>
    <button mat-flat-button class="bg-broad text-white" type="button" (click)="onNext()">
      Avançar
    </button>
  </div>
</div>
