import { Component, inject } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { SharedModule } from '../../../../shared/shared.module';
import { IContact } from '../../../../core/interfaces/IContact';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact-edit-modal',
  standalone: true,
  imports: [AppMaterialModule, SharedModule],
  templateUrl: './contact-edit-modal.component.html',
  styleUrl: './contact-edit-modal.component.scss'
})
export class ContactEditModalComponent {
  readonly dialogRef = inject(MatDialogRef<ContactEditModalComponent>);
  readonly data = inject<{contact:IContact, contactList: IContact[]}>(MAT_DIALOG_DATA);
  readonly formBuilder = inject(FormBuilder);
  readonly snackBar = inject(MatSnackBar);
  form!: FormGroup;

  constructor() {
    this.form = this.formBuilder.group({
      id: [this.data.contact.id],
      name: [this.data.contact.name, Validators.required],
      mail: [this.data.contact.mail, [Validators.required, Validators.email]],
      phone: [this.data.contact.phone, Validators.required],
      role: [this.data.contact.role, Validators.required],
      whatsapp: [this.data.contact.whatsapp],
      mainContact: [this.data.contact.mainContact]
    });
  }

  onSave(){
    if(this.form.invalid){
      this.snackBar.open('Preencha todos os campos.', 'OK');
      return;
    }

    if(this.form.value.mainContact && this.hasMainContact()) {
      this.snackBar.open('Já existe um contato principal.', 'OK');
      return
    }

    this.dialogRef.close(this.form.value);
  }

  hasMainContact () {
    return !!this.data.contactList.find(contact => contact.mainContact);
  }
}
