<h2 mat-dialog-title>Editar Dado Bancário</h2>
<mat-dialog-content>
  <form
    class="container p-2"
    [formGroup]="form"
  >
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Banco</mat-label>
      <mat-select formControlName="bank">
          @for (bank of bankList; track $index) {
            <mat-option [value]="bank">{{ bank.COMPE }} - {{bank.ShortName}}</mat-option>
          }
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Agência</mat-label>
      <input
        matInput
        formControlName="agency"
        withDigit
      >
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Conta Corrente</mat-label>
      <input
        matInput
        formControlName="account"
        withDigit
      >
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Chave Pix <small>(Opcional)</small></mat-label>
      <input matInput formControlName="pix">
    </mat-form-field>

    <div class="row">
      <mat-slide-toggle formControlName="mainAccount">
        Esta conta é a  principal para recebíveis
      </mat-slide-toggle>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between p-2">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button cdkFocusInitial (click)="onSave()" class="bg-broad text-white">Salvar</button>
</mat-dialog-actions>
